.DashboardFooter {
    background-color: var(--Black-20);
    display: flex;
    justify-content: space-between;
    /* padding: 0 7rem; */
    position: fixed;
    width: 100%;
    width: -webkit-fill-available !important;
    bottom: 0;
    z-index: 5;
}

.DashboardFooter p {
    font-size: 0.75rem;
    font-weight: 400;
    color: var(--Black-100);
}

.DashboardFooterLEft {
    display: flex;
    gap: 2rem;
    align-items: center;
}

.MobileDashboardFooterContainer {
    display: flex;
    justify-content: center;
}

.MobileDashboardFooter {
    border-radius: 50%;
    background: #FFF;
    box-shadow: 0px 1px 14.7px 0px rgba(0, 0, 0, 0.3);
    border-radius: 5rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    gap: 1.0625rem;
    width: 80%;
    bottom: 20px;
    position: fixed;
    z-index: 9;
    padding: 1.2rem 1rem;
}

.MobileDashboardFooterItem {
    text-align: center;
    cursor: pointer;
    text-decoration: none;
    color: black;
}

.MobileDashboardFooterItem img {
    width: 1.50013rem;
    height: 1.4375rem;
    margin-bottom: 0.4rem;
}

.MobileDashboardFooterItem p {
    font-size: 0.625rem;
    font-weight: 500;
    margin: 0;
}

.RestartKycContainer {
    background-color: #ebfaf6;
    text-align: center;
    padding: 0.7rem 0;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
    font-size: 0.9rem;
}

.ClickHereButton {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    cursor: pointer;
    color: #407BFF;
}

.RestartKycDialog {
    padding: 4rem 2rem 2rem;
    text-align: center;
}

.RestartKycDialog h3 {
    margin-bottom: 0.5rem;
}

.ResetKycButtonContaner button {
    border-radius: 0.1875rem;
    font-size: 1rem;
    font-weight: 400;
    box-shadow: none;
    padding: 0.3rem 1.5rem;
    width: 6rem;
    margin-top: 1rem;
}

.ResetKycButtonContaner button:nth-child(1) {
    margin-right: 1rem;
    border-radius: 0.1875rem;
    background: #EDEDED;
    color: black;
    font-size: 1rem;
    font-weight: 400;
    box-shadow: none;
}

@media (max-width:567.98px) {

    .RestartKycContainer {
        display: inline;
    }

    .ClickHereButton {
        justify-content: center;
        display: inline-flex;
        align-items: baseline;
    }
}