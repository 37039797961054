body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth
}

a,
div,
h1,
h2,
h3,
h4,
h5,
h6,
li,
p,
small,
span {
    font-family: 'Public Sans', sans-serif
}

:root {
    --wisex-blue: #102348;
    --wisex-green: #00A076;
    --neutrals-black: #1A1A1A;
    --primary-green-300: #55D5B3;
    --neutrals-grey-100: #F0F0F0;
    --neutrals-grey-200: #E6E6E6;
    --neutrals-grey-300: #D9D9D9;
    --neutrals-grey-400: #C9C9C9;
    --neutrals-grey-500: #8791A3;
    --neutrals-grey-700: #757575;
    --neutrals-grey-800: #595959;
    --neutrals-grey-900: #383838;
    --neutrals-grey-1000: #fcfcfcff;
    --neutrals-white: #FFF;
    --primary-green-50: #CCF2E8;
    --neutrals-grey-10: #242424;
    --gradients-green: #00C08D;
    --black-300: #EAEAEA;
    --primary-blue-100: #AEB9D1;
    --primary-blue-500-base: #0B2E74;
    --primary-blue-600: #092661;
    --error-red: #C83535;
    --Neutral-900: #0F0609;
    --Black-60: #A0A0A0;
    --Black-80: #707070;
    --Black-20: #F3F3F3;
    --Black-100: #111;
    --appblack-242424: #242424;
    --Black-40: #CFCFCF;
    --white-gradient: linear-gradient(0deg, #FFF 41.48%, rgba(255, 255, 255, 0.00) 81.5%) !important;
    --listing-card-backdrop-gradient-1: linear-gradient(180deg, #4562FB 0%, #59F6DF 100%);
    --green-backdrop: linear-gradient(90deg, #00C08D 0.06%, #36D1B7 100%);
    --gradients-blue: linear-gradient(270deg, #615DFF 0.06%, #0B2E74 99.93%);
    --wisex-factor: linear-gradient(0deg, rgba(207, 226, 255, 0.20) 0%, rgba(207, 226, 255, 0.20) 100%);
    ---faq-gradient: linear-gradient(80deg, #30E3C1 8.67%, #0B2E74 98.24%);
    --card-box-shadow: 0px 14px 64px -4px rgba(35, 21, 91, 0.05), 0px 8px 12px -6px rgba(35, 21, 91, 0.05) !important
}

.HeaderShowsOnScrollUp {
    position: sticky !important;
    top: 0 !important;
    left: 0 !important;
    width: -webkit-fill-available !important;
    z-index: 7 !important
}

.TestimonialsSlider {
    width: 100% !important;
    overflow: hidden
}

.PrivacyPolicy,
.TermsConditions {
    padding: 5rem 10rem;
    background-color: var(--neutrals-grey-100)
}

.PrivacyPolicy h2,
.TermsConditions h2 {
    color: var(--primary-blue);
    font-weight: 700;
    font-size: 1.125rem;
    margin-top: 4rem
}

.PrivacyPolicy p,
.TermsConditions p {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.8rem;
    color: var(--primary-blue);
    text-align: justify
}

.PrivacyPolicy li,
.TermsConditions li {
    margin-bottom: .5rem;
    font-size: 1.125rem
}

.SdiHowItWorkHeading {
    width: 28rem
}

.MuiAccordion-root {
    box-shadow: none !important
}

.SdiCreditContainer {
    margin-top: 6rem
}

.CorporateBondsHero {
    background-image: url(https://ddk0b51k9qpea.cloudfront.net/public-resources/images/corporate-bg.webp) !important
}

.CorporateBondsContainer .WhatIsSdiTextContainer p {
    width: 80%
}

.CorporateBondsContainer .SDICenterTable {
    border-right: none
}

.CorporateBondsContainer .InvestmentsCompareTabelcontainer {
    margin-top: 5rem
}

.RevealAnimationMotionDiv {
    overflow: hidden
}

.ProductTypeDeopdownListContainer {
    position: relative
}

.ProductTypeDeopdownListContainer button:hover {
    background-color: transparent;
    color: #000
}

.ProductTypeDeopdownList {
    width: 12rem;
    position: absolute;
    top: 30px;
    z-index: 6;
    border-radius: .1875rem;
    background: var(--Black-0, #fff);
    box-shadow: 2.127px 5.318px 11.593px 0 rgba(0, 0, 0, .25);
    padding: .75rem;
    left: .2rem
}

.ProductTypeDropdownListItem {
    display: flex;
    align-items: center;
    gap: .8rem;
    cursor: pointer
}

.ProductTypeDropdownListItem img {
    width: .8rem;
    height: .8rem
}

.ProductTypeDropdownListItem p {
    font-size: .625rem;
    font-weight: 500;
    color: var(--wisex-blue)
}



.FractionalOwnershipCreditRisk .CreditHeadingContainer h1 {
    width: 40rem
}

.InvestmentCalculatorStartWithWisex {
    margin: 5rem 0;
}

@media (max-width:1900px) {
    .MainAppContainer {
        max-width: 100% !important
    }
}

@media (max-width:1600.98px) {
    .CorporateBondsContainer .CorporateBondsRightInnerTable {
        width: 24rem
    }
}

@media (max-width:1260.98px) {
    .CorporateBondsContainer .CorporateBondsRightInnerTable {
        width: auto
    }
}

@media (max-width:767.98px) {

    .PrivacyPolicy,
    .TermsConditions {
        padding: 5rem 2rem
    }

    .PrivacyPolicy li,
    .PrivacyPolicy p,
    .TermsConditions li,
    .TermsConditions p {
        font-size: .8rem
    }

    .PrivacyPolicy h2,
    .TermsConditions h2 {
        font-size: 1rem;
        margin-top: 2rem
    }

    .SdiHowItWorkHeading {
        width: 100%
    }

    .KycRightChildrenLAyout {
        width: auto !important
    }
}

.RemoveOutline:focus-visible {
    outline: 0 !important
}

.RemoveOutline .MuiOutlinedInput-notchedOutline:focus-visible,
.RemoveOutline .MuiSelect-outlined:focus-visible {
    outline: 0 !important
}

.HeaderContent .MuiOutlinedInput-root {
    outline: 0 !important
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none
}

input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield
}

.KycRightChildrenLAyout {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 5rem
}

.KycRightChildrenLAyout li {
    text-align: left;
    margin: 1rem;
    font-size: .75rem;
    font-weight: 400
}

.KycRightChildrenLAyout li::marker {
    color: var(--gradients-green)
}

.GrayGreenButton button {
    border-radius: .4375rem;
    border: .5px solid #a0a0a0;
    background: #f3f3f3;
    display: flex;
    padding: .5rem 2.25rem;
    justify-content: center;
    align-items: center;
    gap: .625rem;
    text-transform: capitalize;
    font-size: 1rem;
    font-weight: 500;
    color: var(--gradients-green)
}

.GrayGreenButtonDigilocker button {
    border-radius: .4375rem;
    border: .5px solid #a0a0a0;
    background: #f3f3f3;
    display: inline-flex;
    padding: .5rem 2.25rem;
    justify-content: center;
    align-items: center;
    gap: .625rem;
    text-transform: capitalize;
    font-size: 1rem;
    font-weight: 500;
    color: var(--gradients-green)
}

.InputLabelSelectMenuItem {
    border-bottom: .5px solid #a0a0a0 !important;
    padding: .62rem 0 !important;
    margin: 0 .8rem !important;
    background-color: #fff !important
}

.PdfViewer .side-menu-container {
    display: none !important
}