.StartWithUsRight {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 0;
    right: 1px;
    height: 20rem;
}

.StartWithUs {
    position: relative;
}

.StartWithUsLeft {
    position: relative;
    z-index: 3;
}

.StartWithUsLeftHeading,
.StartWithUsLeftHeadingSpan {
    width: 36rem;
    font-size: 2.625rem;
    font-weight: 700;
    /* line-height: 3.4125rem; */
    /* letter-spacing: -0.105rem; */
    color: var(--wisex-blue);
    margin-bottom: 0;
}

.StartWithUsLeftHeadingSpan {
    color: var(--wisex-green) !important;
    padding-bottom: 2rem;
}

.StartWithUsRight>image {
    width: 49.94619rem;
    height: 29.5rem;
    object-fit: cover;

}

.StartWithUsBtn {
    padding: .7rem 2.1625rem !important;
    color: var(--neutrals-white);
    text-transform: capitalize !important;
    margin: 2rem 0 !important;
    font-size: 1.125rem !important;
    border-radius: 0.5rem !important;
}


.BackdropContainer {
    position: relative;
}

.StartWithUsBackdrop {
    position: absolute;
    left: 0;
    width: 53.97956rem;
    height: 87.97956rem;
    transform: rotate(15deg);
    flex-shrink: 0;
    border-radius: 97.97956rem;
    opacity: 0.5;
    background: var(--gradients-primary-01, linear-gradient(80deg, #30E3C1 8.67%, #0B2E74 98.24%));
    filter: blur(318.71142578125px);
    z-index: 2;
}



.StartWithUsText {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.8rem;
}

@media (min-width: 1800px) {
    .StartWithUsRight {
        position: initial;
    }
}

@media (max-width: 999px) {

    .StartWithUsLeftHeading,
    .StartWithUsLeftHeadingSpan {
        width: 100%;
    }

    .StartWithUsLeft {
        padding: 0rem;
    }

    .BlogSwiperButtonContainer {
        display: flex;
        align-items: center;
    }

    .StartWithUs {
        padding: 2rem;
        padding-right: 0 !important;
    }

    .StartWithUsLeftHeading,
    .StartWithUsLeftHeadingSpan {
        font-size: 1.5rem;
    }
}


@media (max-width: 768px) {
    .StartWithUsContainerLEft {
        position: relative;
        z-index: 4;
    }

    .StartWithUsLeftHeading,
    .StartWithUsLeftHeadingSpan {
        font-size: 1rem;
    }

    .StartWithUsRight {
        height: auto;
    }

    .StartWithUsRightImg {
        width: 14rem !important;
        height: 12rem;
        object-fit: contain;
        max-width: max-content;
        max-height: max-content;
    }
}