.PrintReceiptContainer {
    position: relative;
}

.PrintReceiptDropdownBody {
    position: absolute;
    top: -5px;
    left: -5px;
    font-size: 0.7rem;
    box-shadow: 0px -1.411px 2.258px 0px rgba(0, 0, 0, 0.13);
    background: #F8F8F9;
    border-radius: 0.3285rem;
    cursor: pointer;
    width: max-content;
}

.PrintReceiptDropdownItem {

    padding: 0px 12px;
}

.NoreceiptText {
    color: #C83535 !important;
}