.WisexAdvantagesContainer {
    padding: 5rem 0rem;
    background-image: url('https://ddk0b51k9qpea.cloudfront.net/public-resources/images/Key+Highlights.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}



.AdvantagesHeadingTextContainer img {
    width: 10rem;
    height: 10rem;
}

.AdvantagesHeadingTextContainer p {
    color: var(--wisex-green);
    font-size: 1.7rem;
    margin-left: 2rem;
}

.AdvantagesHeadingTextContainer {
    display: flex;
    align-items: center;
    color: var(--wisex-green);
    font-size: 1.25rem;
    font-weight: 500;
}

.InvestmentCardText {
    color: #fff;
    width: -webkit-fill-available;
}

.AdvantageText {
    color: var(--primary-blue-100);
    font-size: 1.25rem;
    font-weight: 400;
    width: 37rem;
}

.InvestmentCard {
    padding: 1.5rem;
}

.InvestmentCard:hover {
    background-color: transparent !important;
    cursor: pointer;

}

.WisexAdvantagesFactorContainer {
    margin-top: 4rem;
    /* padding: 0 7rem; */
}

@media (max-width: 999px) {
    .WisexAdvantagesContainer {
        padding: 5rem 0rem;
        background-position: right;
    }

    .WisexAdvantagesFactorContainer {
        padding: 0 1rem;
    }

    .AdvantagesHeadingContainer {
        margin-left: 2rem;
    }

    .InvestmentCard {
        background-color: transparent !important;
        padding: 2rem 0;
    }

    .AdvantageText {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .WisexAdvantagesContainer {
        padding: 3rem 0;
    }

    .AdvantagesHeadingTextContainer img {
        width: 6.07213rem;
        height: 1.375rem;
    }

    .AdvantagesHeadingTextContainer {
        justify-content: center;
    }

    .AdvantagesHeadingTextContainer p {
        margin-left: 1rem;
    }

    .WisexAdvantagesFactorContainer {
        margin-top: 2rem;
        padding: 0 2rem;
    }

    .InvestmentCard {
        display: flex !important;
        align-items: flex-start !important;
        gap: 1.3rem !important;
        border: none !important;
    }

    .InvestmentCardLabel {
        font-size: 0.75rem;
        font-weight: 700;
    }

    .InvestmentCardLTExt {
        font-size: 0.625rem;
        font-weight: 500;
        line-height: 1rem;
    }

    .InvestmentCard img {
        width: 2.5rem;
        height: 2.5rem;
    }
}