.InvestmentCalculatorContainer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: 2rem 0; */
}

.InvestmentCalculatorContainer svg g.MuiChartsAxis-directionY {
    display: none !important;
}

.InvestmentCalculatorWrapper {
    background-color: #f5f9ff;
    border-radius: 1rem;
    padding: 0.75rem 3rem;
    padding-bottom: 2rem;
}

.InvestmentCalculatorInput:focus-visible {
    outline: none;
}

.IncomeCalculatorSliderLabelContainer input:focus-visible {
    outline: none;
}

.IncomeCalculatorSliderLabelContainer input {
    border-radius: 8px;
    background-color: transparent;
    border: 2px solid #c0c5cd;
    border-bottom: 2px solid var(--gradients-green);
    box-shadow: 0 4px 2px -2px gray;
    height: 40px;
    font-weight: 400;
    font-size: 1rem;
    color: #162236;
    font-family: montserrat, sans-serif;
    width: 5rem;
    padding: 0rem 0.5rem;
}

.InvestmentCalculatorInput {
    border: none;
    background: 0 0;
    font-size: 24px;
    font-weight: 600;
    color: #162236;
    margin: 0;
    width: 100%;
    padding: 0.5rem 0;
}

.InvestmentCalculatorInputWrapper {
    border: 2px solid #c0c5cd;
    padding: 10px;
    margin: 20px 0;
    border-radius: 15px;
    border-bottom: 3px solid var(--gradients-green);
    box-shadow: 0 4px 0 1px #c0c5cd;
    margin-bottom: 3rem;
}

.InvestmentCalculatorTopContent p {
    font-size: 1rem;
    margin-top: 0;
}

.IncomeCalculatorSliderMinMaxContainer,
.IncomeCalculatorSliderLabelContainer {
    display: flex;
    justify-content: space-between;
}

.IncomeCalculatorSliderMinMaxContainer p {
    margin: 0;
    font-size: 1rem;
    color: var(--neutrals-grey-500);
}

.IncomeCalculatorSliderWrapper {
    margin: 1rem 0;
}

.IncomeCalculatorSliderWrapper h3 {
    margin: 0;
}

.RentalReturnInputContainer {
    position: relative;
}

.RentalReturnInputContainer p {
    position: absolute;
    top: 12px;
    right: 10px;
    margin: 0;
}

.LineChartContainer {
    position: relative;
    width: min-content;
}

.LineChartContainer svg {
    z-index: 4;

}

.BackgroundGridSVGContainer svg {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    z-index: 1;
}

.LineChartDetailsContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.LineChartDetailsWrapper {
    text-align: center;
}

.LineChartDetailsWrapper small {
    font-size: 0.75rem;
    font-weight: 500;
    color: var(--wisex-blue);
}

.LineChartDetailsWrapper h2,
.LineChartBottomSectionCard h2 {
    margin: 0;
}

.LineChartBottomSectionContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    gap: 10px;
}

.LineChartBottomSectionCard {
    padding: 1rem;
    background-color: #fff;
    border-radius: 5px;
    text-align: center;
    flex: 1;
    border-bottom: 3px solid var(--gradients-green);
}

.LineChartBottomSectionCard p {
    margin: 0.5rem 0;
}

.LineChartBottomSectionTitle {
    margin-top: 1rem;
}

.TotalProfitInPercentageChip {
    font-size: 0.6rem !important;
    font-weight: 700 !important;
    height: 1.2rem !important;
    background-color: var(--gradients-green) !important;
}

.DisclaimerText {
    margin: 0.5rem 1rem 0 1rem;
    font-size: 0.7rem;
    text-align: justify;
    line-height: 16px;
    font-style: italic;
}

@media (max-width: 998px) {
    .LineChartContainer {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .css-1edt01a-MuiResponsiveChart-container {
        width: 100% !important;
        height: 200px !important;
    }
}

@media (max-width: 578px) {
    .InvestmentCalculatorWrapper {
        padding: 1rem;
    }

    .LineChartDetailsContainer {
        flex-direction: column;
        align-items: start;
        gap: 1rem;
    }

    .LineChartDetailsWrapper {
        text-align: left;
    }

    .InvestmentCalculatorWrapper h1 {
        font-size: 1rem;
    }

    .InvestmentCalculatorTopContent p {
        font-size: 0.75rem;
    }

    .InvestmentCalculatorInputWrapper,
    .InvestmentCalculatorInput {
        text-align: center;
    }

    .IncomeCalculatorSliderLabelContainer h3 {
        font-size: 0.8rem;
    }

    .LineChartBottomSectionCard h2 {
        font-size: 1rem;
    }

    .LineChartBottomSectionCard p {
        font-size: 0.8rem;
    }
}